import React, { useEffect } from "react";

import { Col } from "react-bootstrap";
import H1 from "../../../../../components/h1";
import BackButton from "../component/backButton";
import ProfileSetupCTA from "../component/profileSetupCTA";

import PrimaryButton from "../component/button";

import SkeletonLoading from "../component/skeletonLoading";

const SetupNewAgentScene = ({
  setScreen,
  props,
  application,
  fetchApplication,
  isLoading,
}) => {
  const applicationId = localStorage.getItem("applicationId");

  let personalDetailsForm = JSON.parse(
    localStorage.getItem("personalDetailsForm")
  );
  let nextOfKinDetailsForm = JSON.parse(
    localStorage.getItem("nextOfKinDetailsForm")
  );
  let kycDetailsForm = JSON.parse(localStorage.getItem("kycDetailsForm"));
  let passportPhoto = localStorage.getItem("passportPhoto");
  let businessDetailsForm = JSON.parse(
    localStorage.getItem("businessDetailsForm")
  );

  const handleKYC = () => {
    if (personalDetailsForm?.bvn) {
      setScreen(3);
    }
  };
  const handleBussinessDetails = () => {
    if (personalDetailsForm?.bvn && passportPhoto) {
      setScreen(4);
    }
  };
  const handleNOK = () => {
    if (
      personalDetailsForm?.bvn &&
      passportPhoto &&
      businessDetailsForm?.state
    ) {
      setScreen(5);
    }
  };
  const handleKYCApplicationId = () => {
    if (application?.applicantDetails?.bvn) {
      setScreen(3);
    }
  };
  const handleBussinessDetailsApplicationId = () => {
    if (
      application?.applicantDetails?.bvn &&
      application?.documentsList?.length === 1
    ) {
      setScreen(4);
    }
  };
  const handleNOKApplicationId = () => {
    if (
      application?.applicantDetails?.bvn &&
      application?.documentsList?.length === 1 &&
      application?.businessDetails?.state
    ) {
      setScreen(5);
    }
  };

  useEffect(() => {
    if (applicationId && !personalDetailsForm) {
      fetchApplication();
    }
  }, []);

  const handleSubmit = () => {
    if (
      personalDetailsForm?.bvn &&
      passportPhoto &&
      businessDetailsForm?.state &&
      nextOfKinDetailsForm?.phoneNumber
    ) {
      setScreen(6);
    } else if (
      personalDetailsForm?.bvn &&
      passportPhoto &&
      businessDetailsForm?.state
    ) {
      setScreen(5);
    } else if (personalDetailsForm?.bvn && passportPhoto) {
      setScreen(4);
    } else if (personalDetailsForm?.bvn) {
      setScreen(3);
    } else {
      setScreen(2);
    }
  };
  const handleSubmitApplicationId = () => {
    if (
      application?.applicantDetails?.bvn &&
      application?.documentsList?.length === 1 &&
      application?.businessDetails?.state &&
      application?.nextOfKin?.phoneNumber
    ) {
      setScreen(6);
    } else if (
      application?.applicantDetails?.bvn &&
      application?.documentsList?.length === 1 &&
      application?.businessDetails?.state
    ) {
      setScreen(5);
    } else if (
      application?.applicantDetails?.bvn &&
      application?.documentsList?.length === 1
    ) {
      setScreen(4);
    } else if (application?.applicantDetails?.bvn) {
      setScreen(3);
    } else {
      setScreen(2);
    }
  };

  const handleBackButton = () => {
    if (applicationId) {
      props.navigateTo("/agents/applications");
      localStorage.removeItem("applicationId");
      localStorage.removeItem("lga");
      localStorage.removeItem("application");
      localStorage.removeItem("businessType");
      localStorage.removeItem("state");
      localStorage.removeItem("personalDetailsForm");
      localStorage.removeItem("kycDetailsForm");
      localStorage.removeItem("passportPhoto");
      localStorage.removeItem("businessDetailsForm");
      localStorage.removeItem("nextOfKinDetailsForm");
      localStorage.removeItem("jumbScreen");
    } else {
      props.navigateTo("/dashboard");
    }
  };

  const Loading = () => {
    return (
      <div
        style={{
          marginBottom: "25px",
        }}
      >
        {" "}
        <SkeletonLoading width={"100%"} height={"15px"} />{" "}
        <SkeletonLoading width={"100%"} height={"15px"} />
      </div>
    );
  };

  const checkKycDetailsUploads = () => {
    if (application?.documentsList?.length === 1) {
      return true;
    } else {
      return null;
    }
  };

  return (
    <>
      <BackButton onClick={handleBackButton} />
      <main className="aggregator-container">
        <H1
          style={{
            textAlign: "left",
            marginBottom: ".5rem",
          }}
        >
          Setup New Agent
        </H1>

        <p
          style={{
            color: "#6B7280",
            fontSize: "0.75rem",
            marginBottom: "2rem",
          }}
        >
          The following details below are required to set up agents.{" "}
        </p>

        {application?.approvalStatus === "REJECTED" && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "4rem",
              marginBottom: "3rem",
              gap: "10px",
              padding: "0 15px",
            }}
          >
            <div
              style={{
                color: "black",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              Decline Reason:
            </div>
            <div
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: "19px",
              }}
            >
              {application.declineReason}
            </div>
          </div>
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <ProfileSetupCTA
            application={application}
            onClick={() => setScreen(2)}
            actionColor={
              application
                ? application?.applicantDetails?.bvn
                : personalDetailsForm?.bvn
            }
            title={"Business Details Verification"}
            checkClickableTile={
              application ? application?.applicantDetails?.bvn : false
            }
            isLoading={isLoading}
            sub={`Let's verify your business`}
          />
        )}
        {isLoading ? (
          <Loading />
        ) : (
          <ProfileSetupCTA
            application={application}
            onClick={() => setScreen(3)}
            actionColor={
              application
                ? application?.applicantDetails?.bvn
                : personalDetailsForm?.bvn
            }
            title={"BVN & Face ID verification"}
            checkClickableTile={
              application ? application?.applicantDetails?.bvn : false
            }
            isLoading={isLoading}
            sub={`Let's verify your face ID`}
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <ProfileSetupCTA
            declineReason={application?.approvalStatus === "REJECTED"}
            application={application}
            // onClick={application ? handleKYCApplicationId : handleKYC}
            onClick={() => setScreen(4)}
            actionColor={
              application
                ? application?.applicantDetails?.bvn
                : personalDetailsForm?.bvn
            }
            // actionColor={application ? checkKycDetailsUploads() : passportPhoto}
            title={"KYC Information"}
            // checkClickableTile={
            //   application ? application?.applicantDetails?.kyc : passportPhoto
            // }
            checkClickableTile={
              application ? application?.applicantDetails?.bvn : false
            }
            isLoading={isLoading}
            sub={`Agent's identification information for KYC purposes.`}
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <ProfileSetupCTA
            application={application}
            onClick={() => setScreen(5)}
            actionColor={
              application
                ? application?.applicantDetails?.bvn
                : personalDetailsForm?.bvn
            }
            title={"Personal Details"}
            checkClickableTile={
              application ? application?.applicantDetails?.bvn : false
            }
            isLoading={isLoading}
            sub={`Provide us the agent's personal details.`}
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <ProfileSetupCTA
            application={application}
            actionColor={
              application
                ? application?.businessDetails?.state
                : businessDetailsForm?.state
            }
            onClick={
              application
                ? handleBussinessDetailsApplicationId
                : handleBussinessDetails
            }
            checkClickableTile={
              application && application?.businessDetails?.state
                ? true
                : businessDetailsForm
            }
            title={"Business Details"}
            isLoading={isLoading}
            sub={"Tell us who owns this business"}
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <ProfileSetupCTA
            application={application}
            actionColor={
              application
                ? application?.nextOfKin?.phoneNumber
                : nextOfKinDetailsForm?.phoneNumber
            }
            onClick={application ? handleNOKApplicationId : handleNOK}
            checkClickableTile={
              application && application?.businessDetails?.state
                ? true
                : nextOfKinDetailsForm
            }
            title={"Residential Details"}
            isLoading={isLoading}
            sub={`Provide us with your residential address.`}
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <ProfileSetupCTA
            application={application}
            actionColor={
              application
                ? application?.nextOfKin?.phoneNumber
                : nextOfKinDetailsForm?.phoneNumber
            }
            onClick={application ? handleNOKApplicationId : handleNOK}
            checkClickableTile={
              application && application?.businessDetails?.state
                ? true
                : nextOfKinDetailsForm
            }
            title={"Next of Kin Details"}
            isLoading={isLoading}
            sub={`Provide us with the contact information of the agent's next of kin.`}
          />
        )}
        <div
          style={{
            margin: "0 auto",
          }}
        >
          <PrimaryButton
            disabled={isLoading}
            onClick={application ? handleSubmitApplicationId : handleSubmit}
          >
            Continue
          </PrimaryButton>
        </div>
      </main>
    </>
  );
};

export default SetupNewAgentScene;
