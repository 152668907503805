import React, { useEffect, useRef, useState } from "react";
import { Col, Form } from "react-bootstrap";
import H1 from "../../../../components/h1";
import PrimaryButton from "../component/button";
import { ONE_MB } from "../../../../constants";
import Onboarding from "../../../../services/api/resources/onboarding";
import HeaderSignup from "../../../../components/header-signup";
import Stepper from "../component/stepper";
import DocumentUploader from "../../../home/scenes/dashboard/fragments/onboarding-application-setup/component/customeImage4";
import BackButton from "../component/backButton";

const KycDocumentScene = ({ setScreen }) => {
  const onboarding = new Onboarding();

  const [showLightboxPassport, setShowLightboxPassport] = useState(false);
  const [showLightboxSignature, setShowLightboxSignature] = useState(false);
  const [showLightboxId, setShowLightboxId] = useState(false);
  const [loadingPassportCancel, setLoadingPassportCancel] = useState(false);
  const [loadingSignatureCancel, setLoadingSignatureCancel] = useState(false);
  const [loadingIdCardCancel, setLoadingIdCardCancel] = useState(false);
  const [isLoadingDoc, setIsLoadingDoc] = useState(false);
  const [isLoadingSignature, setIsLoadingSignature] = useState(false);
  const [isLoadingIdCard, setIsLoadingIdCard] = useState(false);
  const [fileFormatPassport, setFileFormatPassport] = useState(null);
  const [fileFormatIdCard, setFileFormatIdCard] = useState(null);
  const [fileFormatSignature, setFileFormatSignature] = useState(null);
  const [passportPhoto, setPassportPhoto] = useState(null);
  const [documentPassportPhoto, setDocumentPassportPhoto] = useState(null);
  const [isLoadingLocation, setIsLoadingLocation] = useState(false);
  const [isLoadingPassport, setIsLoadingPassport] = useState(false);
  const [loadingLocationCancel, setLoadingLocationCancel] = useState(false);
  const [showLightboxLocation, setShowLightboxLocation] = useState(false);
  const [fileFormatLocation, setFileFormatLocation] = useState(null);

  const [kycDetailsForm, setKycDetailsForm] = useState({
    documentPassportPhoto: "",
    passportPhoto: "",
    ninImage: "",
    signature: "",
    businessLocation: "",
  });

  const handleCancelLocation = async () => {
    setLoadingLocationCancel(true);

    setKycDetailsForm((prevForm) => {
      const updatedForm = { ...prevForm, businessLocation: "" };
      localStorage.setItem("kycDetailsForm", JSON.stringify(updatedForm));
      return updatedForm;
    });
  };

  const fileInputRefLocation = useRef(null);

  const handleClickLocation = () => {
    fileInputRefLocation.current.click();
  };

  const handleLocationChange = async (event) => {
    setLoadingLocationCancel(false);
    const file = event.target.files[0];
    const reader = new FileReader();

    if (file) {
      if (file.size > ONE_MB) {
        setErrorLocation(true);
        return;
      } else {
        setIsLoadingLocation(true);
        setErrorLocation(false);
        reader.onloadend = () => {
          const result = reader.result;
          setKycDetailsForm((prevForm) => ({
            ...prevForm,
            businessLocation: result,
          }));
          setIsLoadingLocation(false);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  useEffect(() => {
    const savedKycDetailsForm = JSON.parse(localStorage.getItem("kycDetailsForm"));
    if (savedKycDetailsForm) {
      setKycDetailsForm(savedKycDetailsForm);
    }

    const currentAgent = JSON.parse(localStorage.getItem("currentAgent"));
    if (currentAgent && currentAgent.documents) {
      const passportDocument = currentAgent.documents.find(
        (doc) => doc.documentType === "SELFIE_IMAGE"
      );

      if (passportDocument) {
        const url = getDocumentUrl(passportDocument);
        setKycDetailsForm((prevForm) => ({
          ...prevForm,
          passportPhoto: url,
        }));
        setPassportPhoto(url);
      }
    }
  }, []);

  useEffect(() => {
    const currentAgent = JSON.parse(localStorage.getItem("currentAgent"));
    if (currentAgent && currentAgent.documents) {
      const passportDocument = currentAgent.documents.find(
        (doc) => doc.documentType === "SELFIE_IMAGE"
      );
      if (passportDocument && passportDocument.documentLink) {
        fetch(passportDocument.documentLink)
          .then((response) => response.blob())
          .then((blob) => {
            const reader = new FileReader();
            reader.onloadend = function () {
              const base64String = reader.result;

              localStorage.setItem("passportPhotoBase64", base64String);
            };
            reader.readAsDataURL(blob);
          })
          .catch((error) =>
            console.error("Error converting document link to base64:", error)
          );
      }
    }
  }, []);

  const getDocumentUrl = (document) => {
    if (!document) {
      console.error("Document is undefined");
      return "";
    }

    if (!document.documentLink) {
      console.error("documentLink is missing");
      return "";
    }

    return document.documentLink.replace(
      "/data",
      process.env.REACT_APP_ONBOARDING_BASE_URL
    );
  };

  useEffect(() => {
    localStorage.setItem("kycDetailsForm", JSON.stringify(kycDetailsForm));
  }, [kycDetailsForm]);

  const [isLoading, setIsLoading] = useState(false);

  const updateFormField = (params) => {
    const newForm = {
      ...kycDetailsForm,
      ...params,
    };

    setKycDetailsForm(newForm);
  };

  const [uploadError, setUploadError] = useState(false);
  const [errorPassportPhoto, setErrorPassportPhoto] = useState(false);
  const [errorNinImage, setErrorNinImage] = useState(false);
  const [errorSignature, setErrorSignature] = useState(false);
  const [errorLocation, setErrorLocation] = useState(false);

  const getDocumentTypePassport = (file) => {
    const srcSplitted = file?.name
      ? file?.name?.split(".")
      : file?.documentName?.split(".");

    const slittedType = file
      ? srcSplitted[srcSplitted.length - 1].split("-")[0]
      : null;
    setFileFormatPassport(slittedType);
  };
  const fileInputRefPassportPhoto = useRef(null);
  const fileInputRefSignature = useRef(null);
  const fileInputRefIdCard = useRef(null);

  const handleCancelSignature = () => {
    setLoadingSignatureCancel(true);

    setKycDetailsForm((prevForm) => {
      const updatedForm = { ...prevForm, signature: "" };
      localStorage.setItem("kycDetailsForm", JSON.stringify(updatedForm));
      return updatedForm;
    });
  };

  const handleCancelNinImage = () => {
    setLoadingIdCardCancel(true);

    setKycDetailsForm((prevForm) => {
      const updatedForm = { ...prevForm, ninImage: "" };
      localStorage.setItem("kycDetailsForm", JSON.stringify(updatedForm));
      return updatedForm;
    });
  };

  const handleCancelPassportImage = () => {
    setLoadingIdCardCancel(true);

    setKycDetailsForm((prevForm) => {
      const updatedForm = { ...prevForm, documentPassportPhoto: "" };
      localStorage.setItem("kycDetailsForm", JSON.stringify(updatedForm));
      return updatedForm;
    });
  };

  const handleClickSignature = () => {
    fileInputRefSignature.current.click();
  };
  const handleClickIdCardPhoto = () => {
    fileInputRefIdCard.current.click();
  };
  const handleClickPassportPhoto = () => {
    fileInputRefPassportPhoto.current.click();
  };

  const handleSignatureChange = async (event) => {
    setLoadingSignatureCancel(false);
    const file = event.target.files[0];

    if (file) {
      if (file.size > ONE_MB) {
        setErrorSignature(true);
        return;
      } else {
        setIsLoadingSignature(true);
        setErrorSignature(false);
        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result;
          setKycDetailsForm((prevForm) => ({
            ...prevForm,
            signature: result,
          }));
          setIsLoadingSignature(false);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleNinChange = async (event) => {
    setLoadingIdCardCancel(false);
    const file = event.target.files[0];

    if (file) {
      if (file.size > ONE_MB) {
        setErrorNinImage(true);
        return;
      } else {
        setIsLoadingIdCard(true);
        setErrorNinImage(false);
        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result;
          setKycDetailsForm((prevForm) => ({
            ...prevForm,
            ninImage: result,
          }));
          setIsLoadingIdCard(false);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handlePassportChange = async (event) => {
    setLoadingLocationCancel(false);
    const file = event.target.files[0];

    if (file) {
      if (file.size > ONE_MB) {
        setErrorPassportPhoto(true);
        return;
      } else {
        setIsLoadingPassport(true);
        setErrorPassportPhoto(false);
        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result;
          setDocumentPassportPhoto(result);
          setKycDetailsForm((prevForm) => ({
            ...prevForm,
            documentPassportPhoto: result,
          }));
          localStorage.setItem("documentPassportPhoto", result);
          setIsLoadingPassport(false);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const isFormValid = () => {
    return (
      kycDetailsForm.signature &&
      kycDetailsForm.ninImage &&
      (kycDetailsForm.documentPassportPhoto || kycDetailsForm.passportPhoto)
    );
  };


  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          gap: '40px',
        }}
      >
        <HeaderSignup />
        <BackButton onClick={() => setScreen(2)} />
      </div>
      <main className="onboarding-container">
        <Col
          style={{
            margin: "20px auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: "10px",
              marginBottom: "30px",
            }}
          >
            <Stepper borderColor="#00425F" />
            <Stepper borderColor="#00425F" />
            <Stepper />
          </div>
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: ".2rem",
            }}
          >
            KYC Details
          </H1>

          <div>
            {kycDetailsForm?.passportPhoto ? (
              <img
                src={kycDetailsForm?.passportPhoto}
                alt="Passport Photo"
                className="passport-photo"
              />
            ) : (
              <DocumentUploader
                label={"Passport"}
                loading={isLoadingDoc || isLoadingPassport}
                loadingCancelDoc={loadingPassportCancel}
                showLightbox={showLightboxPassport}
                fileFormat={fileFormatPassport}
                state={kycDetailsForm?.documentPassportPhoto || ""}
                setShowLightbox={setShowLightboxPassport}
                onChange={handlePassportChange}
                handleDocument={handleClickPassportPhoto}
                fileInputRef={fileInputRefPassportPhoto}
                handleCancel={handleCancelPassportImage}
                error={errorPassportPhoto}
              />
            )}
          </div>


          <br />
          <DocumentUploader
            label={"Upload NIN Slip Image"}
            loading={isLoadingDoc || isLoadingIdCard}
            loadingCancelDoc={loadingIdCardCancel}
            showLightbox={showLightboxId}
            fileFormat={fileFormatIdCard}
            state={kycDetailsForm?.ninImage ? kycDetailsForm?.ninImage : ""}
            setShowLightbox={setShowLightboxId}
            onChange={handleNinChange}
            handleDocument={handleClickIdCardPhoto}
            fileInputRef={fileInputRefIdCard}
            handleCancel={handleCancelNinImage}
            error={errorNinImage}
          />

          <br />
          <DocumentUploader
            label={"Signature"}
            loading={isLoadingDoc || isLoadingSignature}
            loadingCancelDoc={loadingSignatureCancel}
            showLightbox={showLightboxSignature}
            fileFormat={fileFormatSignature}
            state={
              kycDetailsForm?.signature
                ? kycDetailsForm?.signature
                : ""
            }
            setShowLightbox={setShowLightboxSignature}
            onChange={handleSignatureChange}
            handleDocument={handleClickSignature}
            fileInputRef={fileInputRefSignature}
            handleCancel={handleCancelSignature}
            error={errorSignature}
          />

          <br />
          <DocumentUploader
            label={"Other Supporting Documents"}
            loading={isLoadingDoc || isLoadingLocation}
            loadingCancelDoc={loadingLocationCancel}
            showLightbox={showLightboxLocation}
            fileFormat={fileFormatLocation}
            state={kycDetailsForm?.businessLocation ? kycDetailsForm?.businessLocation : ""}
            setShowLightbox={setShowLightboxLocation}
            onChange={handleLocationChange}
            handleDocument={handleClickLocation}
            fileInputRef={fileInputRefLocation}
            handleCancel={handleCancelLocation}
            error={errorLocation}
          />
          {uploadError && (
            <Col lg={12}>
              <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                Failed to Upload.Try again
              </span>
            </Col>
          )}

          <PrimaryButton
            disabled={!isFormValid() || isLoading || isLoadingDoc}
            onClick={() => setScreen(4)}
          >
            {isLoading ? "Processing..." : "Next"}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

export default KycDocumentScene;
