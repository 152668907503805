import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useLocation } from "react-router-dom";

import Title from "../component/title";
import H1 from "../../../../../components/h1";
import { toast } from "react-toastify";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../../constants/api";
import { notify } from "../../../../../components/extras";
import BackButton from "../component/backButton";
import InputText from "../component/input";
import { stripNonNumbers } from "../component/extras";
import Platform from "../../../../../services/api/resources/platform";

const NINVerificationScene = ({ application, setScreen }) => {
  const history = useHistory();
  const platform = new Platform();
  const location = useLocation();
  const data = location.state;

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ninVerificationForm, setNinVerificationForm] = useState({
    nin: application ? application?.applicantDetails?.ninVerification?.nin : "",
  });

  useEffect(() => {
    const updatedNINVerificationForm = JSON.parse(
      localStorage.getItem("ninVerification")
    );

    if (updatedNINVerificationForm) {
      setNinVerificationForm({
        nin:
          application?.applicantDetails?.ninVerification?.nin ||
          updatedNINVerificationForm.nin ||
          "",
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem(
      "ninVerificationForm",
      JSON.stringify(ninVerificationForm)
    );
  }, [ninVerificationForm]);

  const updateFormField = (params) => {
    const newForm = {
      ...ninVerificationForm,
      ...params,
    };

    setNinVerificationForm(newForm);
  };

  const handleSubmit = async () => {
    setScreen(5);
    // setLoading(true);
    // const payload = {
    //   identificationNumber: ninVerificationForm.nin,
    // };

    // const { status, response } = await platform.validateNINDetails(
    //   payload,
    //   "NIN"
    // );

    // if (status === ERROR_STATUS) {
    //   notify(response.description, "error");
    //   setLoading(false);
    // } else if (status === SUCCESS_STATUS) {
    //   // await loadAgent();

    //   if (response.data.validationStatus === "VERIFIED") {
    //     notify(response?.data?.message, "success");
    //     setScreen(5)
    //     // history.push("/business-cbn");
    //   } else if (response?.data?.validationStatus === "NOT_VERIFIED") {
    //     notify(response?.data?.message, "error");
    //     setLoading(false);
    //   } else {
    //     setLoading(false);
    //   }
    // } else {
    //   setLoading(false);
    // }
  };

  const isNinValid = (nin) => {
    const ninPattern = /^\d{11}$/;
    return ninPattern.test(nin);
  };

  const isFormValid = () => {
    return ninVerificationForm.nin && isNinValid(ninVerificationForm.nin);
  };

  const checkAuthentication = () => {
    const isAuthenticated = localStorage.getItem("auth_token") === null;

    if (isAuthenticated) {
      history.push("/login");
      localStorage.clear();
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  // let kycCheckListDetails = JSON.parse(localStorage.getItem("currentAgent"));
  return (
    <>
      <BackButton
        onClick={() => {
          setScreen(3);
        }}
      />
      <main className="onboarding-container">
        <div
          style={{
            marginBottom: "20px",
            margin: "0 auto",
          }}
        >
          <Title pageNum={"3"} />
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
            }}
          >
            KYC Information
          </H1>

          <InputText
            label={"National Identification Number (NIN)"}
            labelColor={
              !ninVerificationForm.nin || isNinValid(ninVerificationForm.nin)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !ninVerificationForm.nin || isNinValid(ninVerificationForm.nin)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your NIN"}
            onChange={(e) => {
              updateFormField({ nin: stripNonNumbers(e.target.value.trim()) });
            }}
            maxLength={11}
            value={ninVerificationForm.nin}
          />

          <button
            onClick={handleSubmit}
            className="signupButton2"
            style={{
              marginTop: "1rem",
              textAlign: "center",
              color: "white",
              backgroundColor: "#00425f",
              width: "100%",
            }}
            disabled={!isFormValid() || loading}
          >
            {loading ? "Processing..." : "Next"}
          </button>
        </div>
      </main>
    </>
  );
};

export default NINVerificationScene;
