import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import H1 from "../../../../components/h1";
import BackButton from "../component/backButton";

import HeaderSignup from "../../../../components/header-signup";
import Text from "../../../../components/text";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import CountriesStatesLga from "../../../../fixtures/countries_states_lgas.json";
import Onboarding from "../../../../services/api/resources/onboarding";
import PreviewAccordion from "../component/accordion";
import PrimaryButton from "../component/button";
import { connect } from "react-redux";
import { navigateTo } from "../../../../services/redux/actions/navigation";
import { useHistory } from "react-router-dom";
import { ArrowDownIcon } from "../../../../icons";

const secureAccountDetailsForm = JSON.parse(localStorage.getItem('secureAccountDetailsForm'));
const bvnInformation = JSON.parse(localStorage.getItem('bvnInformation'));
const bvnDetailsForm = JSON.parse(localStorage.getItem('bvnDetailsForm'));
const KycInformation = ({
  setScreen,
  application,
  isLoading,
}) => {
  const [kycDetails, setKycDetails] = useState(null);

  useEffect(() => {
    const storedKycDetails = JSON.parse(localStorage.getItem('kycDetailsForm'));
    setKycDetails(storedKycDetails);
  }, []);

  return (
    <>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          NIN:
        </Text>
        <Text style={{ color: "grey" }} mid>
          {isLoading ? "Loading..." : kycDetails?.nin}
        </Text>
      </div>
    </>
  );
};
const PersonalDetails = ({ setScreen, application, isLoading }) => {
  const [personalDetails, setPersonalDetails] = useState(null);

  useEffect(() => {
    const storedPersonalDetails = JSON.parse(localStorage.getItem('personalDetailsForm'));
    setPersonalDetails(storedPersonalDetails);
  }, []);
  return (
    <>
      <div
        onClick={() => setScreen(5)}
        style={{
          float: "right",
          cursor: "pointer",
          color: "#00425F",
          fontWeight: 600,
        }}
      >
        Edit
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          First Name:
        </Text>
        <Text style={{ color: "grey" }} mid>
          {isLoading ? "Loading..." : application?.firstName}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Middle Name:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {isLoading ? "Loading..." : application?.middleName}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Last Name:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {isLoading ? "Loading..." : application?.lastName}
        </Text>
      </div>

      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          BVN Phone Number:
        </Text>
        <Text style={{ color: "grey" }} mid>
          {isLoading ? "Loading..." : application?.bvnPhoneNumber}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Email Address:
        </Text>
        <Text style={{ color: "grey" }} mid>
          {isLoading ? "Loading..." : application?.emailAddress}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Date of Birth:
        </Text>
        <Text style={{ color: "grey" }} mid>
          {isLoading ? "Loading..." : application?.dob}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Proposed Phone Number:
        </Text>
        <Text style={{ color: "grey" }} mid>
          {isLoading ? "Loading..." : application?.proposedNumber}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Place of Birth:
        </Text>
        <Text style={{ color: "grey" }} mid>
          {isLoading ? "Loading..." : personalDetails?.birthPlace}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Mother's Maiden Name:
        </Text>
        <Text style={{ color: "grey" }} mid>
          {isLoading ? "Loading..." : personalDetails?.maidenName}
        </Text>
      </div>
    </>
  );
};

const ApplicationReviewScene = ({ setScreen, props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState(false);
  const [state, setState] = useState("");
  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const [LocalGovernmentArea, setLocalGovernmentArea] = useState("");
  const onboarding = new Onboarding();

  const handleSubmit = async () => {
    setLoading(true);
    // applicationId
    // const payload = {};
    // const { status, response } = await onboarding.saveAggregatorApplication(
    //   payload,
    //   applicationId
    // );

    // setLoading(false);
    // if (status === ERROR_STATUS) {
    //   firetoast("error", `${response.description}`, "");
    // } else if (status === SUCCESS_STATUS) {
    //   congratsModal();
    //   localStorage.removeItem("applicationId");
    //   localStorage.removeItem("lga");
    //   localStorage.removeItem("application");
    //   localStorage.removeItem("state");
    //   localStorage.removeItem("passportPhoto");
    //   localStorage.removeItem("idCard");
    //   localStorage.removeItem("personalDetailsForm");
    //   localStorage.removeItem("kycDetailsForm");
    //   localStorage.removeItem("businessDetailsForm");
    //   localStorage.removeItem("nextOfKinDetailsForm");
    // }

    localStorage.removeItem("personalDetailsForm");
    localStorage.removeItem("kycDetailsForm");
    localStorage.removeItem("secureAccountDetailsForm");
    localStorage.removeItem("bvnInformation");
    localStorage.removeItem("bvnDetailsForm");
    setScreen(6);

  };

  const [showAccordion, setAccordion] = useState(true);
  const [showAccordionKYC, setAccordionKYC] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "40px",
        }}
      >
        <HeaderSignup />
        <BackButton onClick={() => setScreen(4)} />
      </div>
      <main className="onboarding-container">
        <Col
          style={{
            marginBottom: "20px",
            margin: "0 auto",
          }}
        >
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
            }}
          >
            Application Review
          </H1>

          <PreviewAccordion
            index={"0"}
            title={"KYC Information"}
            showAccordion={showAccordionKYC}
            onClick={() => setAccordionKYC(!showAccordionKYC)}
          >
            <KycInformation
              setScreen={setScreen}
              application={application.applicantDetails}
            />
          </PreviewAccordion>

          <PreviewAccordion
            index="1"
            title={"Personal Details"}
            showAccordion={showAccordion}
            onClick={() => setAccordion(!showAccordion)}
          >
            <div>
              <PersonalDetails
                application={application.applicantDetails}
                isLoading={isLoading}
                setScreen={setScreen}
              />
            </div>
          </PreviewAccordion>

          <PrimaryButton disabled={loading || isLoading} onClick={handleSubmit}>
            {loading ? "Processing..." : "Submit"}{" "}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};
function mapStateToProps(state) {
  return {
    // isLoading: state.tunnel.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationReviewScene);
