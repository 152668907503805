import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "../../../../../components/cbn-onboarding/style.css";
import Platform from "../../../../../services/api/resources/platform";
import Onboarding from "../../../../../services/api/resources/onboarding";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../../constants/api";
import CustomError from "../../../../../components/cbn-onboarding/error";
import { notify } from "../../../../../components/extras";
import BackButton from "../component/backButton";
import Title from "../component/title";
import H1 from "../../../../../components/h1";

const FaceVerificationSceneAggregator = ({ setScreen, application }) => {
  const location = useLocation();
  const platform = new Platform();
  const onboarding = new Onboarding();
  const data = location.state;

  const history = useHistory();

  const [error, setError] = useState(false);
  const [failed, setFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Unable to complete facial verification."
  );

  const [isLoading, setIsLoading] = useState(false);

  const navigateToBvnValidation = () => {
    history.push("/bvn-validation");
  };

  const handleStatus = async () => {
    setIsLoading(true);

    let success = false;

    while (!success) {
      const { status, response } = await platform.submitStatusImageCapturing(
        data.jobId
      );

      if (status === SUCCESS_STATUS && response.description === "Successful") {
        success = true;
        notify("Your face ID verification was successful", "success");
        setScreen(4);
        // history.push({
        //   pathname: "/nin-verification",
        //   state: { disableBackButton: true },
        // });
        break;
      } else if (
        status === SUCCESS_STATUS &&
        response.description === "Pending"
      ) {
        await new Promise((resolve) => setTimeout(resolve, 10000));
      } else if (response.description === "Failed") {
        setIsLoading(false);
        setError(true);
        setFailed(true);
        setErrorMessage(
          "We couldn’t validate your BVN this time, kindly try again later"
        );

        break;
      }
    }
  };

  const handleUpload = async (images) => {
    setScreen(4);
    // setIsLoading(true);

    // const payload = {
    //   jobId: data.jobId,
    //   bvnNumber: +data.bvn,
    //   imageRequest: { images: images },
    // };
    // const { status, response } = await platform.submitUploadImageCapturing(
    //   payload
    // );
    // setIsLoading(false);
    // if (status === ERROR_STATUS) {
    //   setError(true);
    // } else if (status === SUCCESS_STATUS) {
    //   handleStatus();
    // }
  };

  useEffect(() => {
    const app = document.querySelector("smart-camera-web");

    const postContent = async (data) => {
      await handleUpload(data.detail.images);
    };

    if (app) {
      app.addEventListener("imagesComputed", postContent);
    }

    return () => {
      if (app) {
        app.removeEventListener("imagesComputed", postContent);
      }
    };
  }, [error]);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p
            style={{
              textAlign: "center",
              fontSize: "14px",
              color: "white",
              paddingTop: "10px",
              width: "20rem",
            }}
          >
            Please wait for about 3-5 minutes while we validate your details. Do
            not close your browser.
          </p>
        </div>
      )}
      <BackButton
        onClick={() => {
          setScreen(3);
        }}
      />
      <main className="onboarding-container">
        <div
          style={{
            marginBottom: "20px",
          }}
        >
          {error ? (
            <>
              <CustomError
                isLoading={false}
                checkFailure={failed}
                errorMessage={errorMessage}
                onRetry={() => {
                  failed ? setScreen(3) : setError(false);
                }}
              />
            </>
          ) : (
            <>
              <Title pageNum={"2"} />
              <H1
                style={{
                  textAlign: "left",
                  fontWeight: 600,
                  marginBottom: "1rem",
                }}
              >
                Face ID Verification
              </H1>
              <p
                style={{
                  color: "#5F738C",
                  fontWeight: "500",
                  fontSize: "0.8rem",
                  letterSpacing: "0.3px",
                }}
              >
                Ensure your camera is steady and your face fits into the box
                below
              </p>

              <div
                style={{
                  margin: "1rem",
                }}
              >
                <smart-camera-web hide-attribution></smart-camera-web>
              </div>
            </>
          )}
        </div>
      </main>
    </>
  );
};

export default FaceVerificationSceneAggregator;
