import React, { useState } from "react";
// import { Col, Row } from 'react-bootstrap';
import MdNotifications from "react-ionicons/lib/MdNotifications";
import MdSync from "react-ionicons/lib/MdSync";

import { formatWalletDate } from "../../../../../utils/formatters";
import ApplicationStatusIndicators from "../fragments/application-status-indicators";
import PopUpModal from "./ninModal";
import {
  AgentBanner,
  PendingApprovalBanner,
  PendingAttestationBanner,
  PendingSubmissionBanner,
} from "../../manage-profile/component/bannerHeaderDashboard";

const COLOUR = "white";

const UpdateButton = (props) => {
  const currentDate = new Date();
  const isAfterMarch31st =
    currentDate.getTime() > new Date(2024, 3, 31).getTime();
  let application = JSON.parse(localStorage.getItem("currentAgent"));
  let modifiedAgentDetails = JSON.parse(
    localStorage.getItem("modifiedAgentDetails")
  );
  const isAfterMarch1st =
    currentDate.getTime() > new Date(2024, 3, 1).getTime();

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const [cancelBanner, setCancelBanner] = useState(true);

  const closeBanner = () => {
    setCancelBanner(false);
  };

  console.log(application, "application");
  return (
    <div
      style={{
        color: COLOUR,
        display: "flex",
        justifyContent: "space-between",
        fontSize: "12px",
      }}
    >
      <div
        normal="true"
        leftAlign="true"
        onClick={props.disabled ? undefined : props.onClick}
        // props.disabled
        //         ? undefined
        //         : () => props.navigateTo('/notifications')
        style={{
          zIndex: "1",
          cursor: props.disabled ? "not-allowed" : "pointer",
        }}
      >
        <MdSync
          color={COLOUR}
          fontSize="26pt"
          rotate={props.isLoading}
          style={{ marginRight: "5pt" }}
        />
        UPDATE
        {props.disabled
          ? undefined
          : props.wallet.wallet_date && (
              <h6
                style={{
                  fontSize: "10px",
                  opacity: !props.wallet.wallet_date ? 0.01 : 1,
                }}
              >
                {props.wallet.wallet_date
                  ? `Last updated ${formatWalletDate(props.wallet.wallet_date)}`
                  : "@"}{" "}
              </h6>
            )}
      </div>

      {(process.env.REACT_APP_UPGRADE_STANDARD === "true" &&
        application?.agentClass === "BASIC" &&
        modifiedAgentDetails &&
        modifiedAgentDetails?.count === 0) ||
      modifiedAgentDetails?.content[0]?.status === 0 ||
      modifiedAgentDetails?.content[0]?.status === 1 ? (
        <div
          style={{
            zIndex: 10,
          }}
        >
          {cancelBanner && <AgentBanner closeBanner={closeBanner} />}
        </div>
      ) : modifiedAgentDetails?.content[0]?.status === 5 ? (
        <div
          style={{
            zIndex: 10,
          }}
        >
          {cancelBanner && (
            <PendingAttestationBanner closeBanner={closeBanner} />
          )}
        </div>
      ) : modifiedAgentDetails?.content[0]?.status === 2 ? (
        <div
          style={{
            zIndex: 10,
          }}
        >
          {cancelBanner && <PendingApprovalBanner closeBanner={closeBanner} />}
        </div>
      ) : modifiedAgentDetails?.content[0]?.status === 6 ? (
        <div
          style={{
            zIndex: 10,
          }}
        >
          {cancelBanner && (
            <PendingSubmissionBanner closeBanner={closeBanner} />
          )}
        </div>
      ) : (
        ""
      )}

      <div
        style={{
          zIndex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {!props.deviceIsMobile && (
          <ApplicationStatusIndicators
            disabled={props.disabled}
            currentAgent={props.currentAgent}
            currentUser={props.currentUser}
            currentApplication={props.currentApplication || {}}
            navigateTo={props.navigateTo}
            toShowSetupInProgress={props.toShowSetupInProgress}
            toShowAwaitingApproval={props.toShowAwaitingApproval}
            toShowAwaitingValidation={props.toShowAwaitingValidation}
            toShowCompleteApplication={props.toShowCompleteApplication}
            toShowInactive={props.toShowInactive}
            toShowSuspended={props.toShowSuspended}
            agentClass={props.currentAgent.agentClass}
            rejectedApplication={props.rejectedApplication}
            mobile={props.deviceIsMobile}
          />
        )}

        <div
          xs={6}
          md={3}
          style={{
            zIndex: "1",
            display: "flex",
            justifyContent: "space-around",
            colour: COLOUR,
            textAlign: "right",
            marginRight: "5px",
          }}
        >
          <div
            className=""
            style={{ cursor: props.disabled ? "not-allowed" : "pointer" }}
            onClick={
              props.disabled
                ? undefined
                : () => props.navigateTo("/notifications")
            }
            color={
              props.disabled ? process.env.REACT_APP_LIGHT_GREY_COLOUR : COLOUR
            }
          >
            <MdNotifications
              shake={props.listenerValue}
              color={COLOUR}
              fontSize="24pt"
              style={{ marginRight: "10pt" }}
            />
          </div>
          <div>
            <b style={{ marginTop: "10px" }}>
              {props.currentUser.domainName
                ? props.currentUser.domainName.toUpperCase()
                : ""}
            </b>
            {process.env.REACT_APP_UPGRADE_STANDARD === "true" && (
              <p
                style={{
                  backgroundColor: "gray",
                  opacity: "0.6",
                  color: "white",
                  padding: "6px",
                  borderRadius: "2px",
                  textTransform: "capitalize",
                }}
              >
                <span style={{ fontSize: "10px", fontWeight: "thin" }}>
                  Account Type:
                </span>{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textTransform: "capitalize",
                  }}
                >
                  {application?.agentClass &&
                    application?.agentClass.slice(0).toLowerCase()}
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateButton;
