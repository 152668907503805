import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";

import MdCheckmarkCircle from "react-ionicons/lib/MdCheckmarkCircle";
import { toast } from "react-toastify";

import Title from "../component/title.js";
import { Col } from "react-bootstrap";
import H1 from "../../../../../components/h1.js";
import Platform from "../../../../../services/api/resources/platform.js";
import BackButton from "../../../../signup/onboarding-setup/component/backButton.js";
import InputText from "../../../../signup/onboarding-setup/component/input.js";
import InputWithSelectText from "../../../../signup/onboarding-setup/component/inputWithSelect.js";

const BusinessDetailsVerfication = ({ application, setScreen }) => {
  const history = useHistory();
  const platform = new Platform();

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tinValidated, setTinValidated] = useState(false);
  const [businessType, setBusinessType] = useState("BN");
  const [tinSubmitted, setTinSubmitted] = useState(false);
  const [businessSceneForm, setBusinessSceneForm] = useState({
    tin: application?.applicantDetails?.businessScene?.tin || "",
    cacNumber: application?.applicantDetails?.businessScene?.cacNumber || "",
  });
  const [selectedValue, setSelectedValue] = useState("BN");

  useEffect(() => {
    const updateBusinessForm = JSON.parse(localStorage.getItem("businessForm"));
    if (updateBusinessForm) {
      setBusinessSceneForm({
        tin: updateBusinessForm.tin || businessSceneForm.tin,
        cacNumber: updateBusinessForm.cacNumber || businessSceneForm.cacNumber,
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem(
      "businessSceneForm",
      JSON.stringify(businessSceneForm)
    );
  }, [businessSceneForm]);

  const updateFormField = (params) => {
    setBusinessSceneForm((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const notify = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const congratsModal = () => {
    setShowModal(true);
  };

  const handleSubmit = async () => {
    setScreen(3);
    // setLoading(true);

    // const payload = tinValidated
    //   ? {
    //       identificationNumber: businessSceneForm.cacNumber,
    //       prefix: selectedValue,
    //     }
    //   : { identificationNumber: businessSceneForm.tin };

    // const validationType = tinValidated ? "CAC" : "TIN";

    // try {
    //   const { status, response } = await platform.validateBusinessDetails(
    //     payload,
    //     validationType
    //   );

    //   if (response.code !== "00") {
    //     notify(response.description, "error");
    //   } else if (response.data.validationStatus === "VERIFIED") {
    //     // await loadAgent();
    //     notify(response.data.message, "success");
    //     if (!tinValidated) {
    //       setTinValidated(true);
    //       setTinSubmitted(true);
    //       updateFormField({ cacNumber: response.data.cacRegNo });
    //     } else {
    //       congratsModal();
    //     }
    //   } else {
    //     notify(response.data.message, "error");
    //   }
    // } catch (error) {
    //   notify(
    //     "An error occurred while validating your details, please try again.",
    //     "error"
    //   );
    // } finally {
    //   setLoading(false);
    // }
  };

  const navigateToNINVerification = () => {
    history.push("/nin-verification");
  };

  const isTinValid = (tin) => {
    const tinPattern = /^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{9,14}$/;
    return tinPattern.test(tin);
  };

  const isCacNumberValid = (cacNumber) => {
    const cacNumberPattern =
      /^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8}$/;
    return cacNumberPattern.test(cacNumber);
  };

  const isFormValid = () => {
    return isTinValid(businessSceneForm.tin);
    //  &&
    // (tinValidated ? isCacNumberValid(businessSceneForm.cacNumber) : true)
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const checkAuthentication = () => {
    const isAuthenticated = localStorage.getItem("auth_token") === null;

    if (isAuthenticated) {
      history.push("/login");
      localStorage.clear();
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);
  // let kycCheckListDetails = JSON.parse(localStorage.getItem("currentAgent"));
  return (
    <>
      <BackButton
        onClick={() => {
          localStorage.removeItem("businessDetailsForm");
          setScreen(1);
        }}
      />
      <main className="aggregator-container">
        <Col
          style={{
            marginBottom: "20px",
            margin: "0 auto",
          }}
        >
          <Title pageNum={"1"} />
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
            }}
          >
            Business details
          </H1>

          <div style={{ position: "relative" }}>
            <InputText
              label={"Enter your Tax Identification Number (TIN)"}
              labelColor={
                !businessSceneForm.tin || isTinValid(businessSceneForm.tin)
                  ? "black"
                  : process.env.REACT_APP_RED_COLOUR
              }
              border={`1px solid ${
                !businessSceneForm.tin || isTinValid(businessSceneForm.tin)
                  ? "#DFE2E6"
                  : process.env.REACT_APP_RED_COLOUR
              }`}
              placeholder={"Enter TIN"}
              onChange={(e) => {
                updateFormField({ tin: e.target.value.trim() });
              }}
              maxLength={14}
              value={businessSceneForm.tin}
              disabled={tinValidated}
            />
            {tinValidated && (
              <MdCheckmarkCircle
                style={{
                  position: "absolute",
                  top: "70%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  width: "30px",
                  height: "30px",
                }}
                color="green"
                fontSize="40px"
              />
            )}
            {tinValidated && (
              <div style={{}}>
                <InputWithSelectText
                  label={"Select your Prefix"}
                  // labelColor={
                  //   !businessSceneForm.cacNumber ||
                  //   isCacNumberValid(businessSceneForm.cacNumber)
                  //     ? "black"
                  //     : process.env.REACT_APP_RED_COLOUR
                  // }
                  placeholder={"Enter number"}
                  value={businessSceneForm.cacNumber}
                  maxLength={8}
                  // border={`1px solid ${
                  //   !businessSceneForm.cacNumber ||
                  //   isCacNumberValid(businessSceneForm.cacNumber)
                  //     ? "#DFE2E6"
                  //     : process.env.REACT_APP_RED_COLOUR
                  // }`}
                  // loading={loading}
                  selectOptions={[
                    { label: "BN", value: "BN" },
                    { label: "LP", value: "LP" },
                    { label: "RC", value: "RC" },
                    { label: "LLP", value: "LLP" },
                    { label: "IT", value: "IT" },
                  ]}
                  selectValue={selectedValue}
                  onSelectChange={handleSelectChange}
                  disabled={true}
                />
              </div>
            )}
            <div
              style={{
                fontSize: "14px",
                marginBottom: "1.5rem",
                marginTop: "-10px",
              }}
            >
              Are you a Government Biller?{" "}
              <span
                style={{
                  color: process.env.REACT_APP_LINK_COLOUR,
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  setScreen(8);
                }}
              >
                Click here
              </span>
            </div>
          </div>

          <button
            onClick={handleSubmit}
            className="signupButton2"
            style={{
              marginTop: "1rem",
              textAlign: "center",
              color: "white",
              backgroundColor: "#00425f",
              width: "100%",
            }}
            disabled={!isFormValid() || loading}
          >
            {loading ? "Processing..." : tinSubmitted ? "Submit" : "Next"}
          </button>
        </Col>
      </main>
    </>
  );
};

export default BusinessDetailsVerfication;
