import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import InputText from "../component/input";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import MdCheckmarkCircle from "react-ionicons/lib/MdCheckmarkCircle";

import IosRefresh from "react-ionicons/lib/IosRefresh";
import Onboarding from "../../../../../services/api/resources/onboarding";
import UserManagement from "../../../../../services/api/resources/user-management";
import {
  ERROR_STATUS,
  HTTP_NOT_FOUND_CODE,
  SUCCESS_CODE,
  SUCCESS_STATUS,
} from "../../../../../constants/api";
import FormLabel from "../../../../../components/form-label";
import { isPasswordValid } from "../../../../signup/onboarding-setup/component/extras";
import H1 from "../../../../../components/h1";
import { isBusinessNameValid, isNameValid } from "../component/extras";
import {
  isEmailValid,
  isNumberValid,
  isPhoneValid,
} from "../../../../../utils/validators/form-validators";
import SelectInput from "../component/select";
import BackButton from "../component/backButton";
import {
  formatEmailOrPhone,
  specialDateRangeFormatter_StartDateDate,
} from "../../../../../utils/formatters";
import { firetoast, notify } from "../../../../../components/extras";
import { isConfirmPasswordValid } from "../../manage-profile/component/extras";
import phoneValidators from "../../../../../utils/validators/phone-validators";
import { NIGERIA_PHONE_PREFIX } from "../../../../../constants";
import PrimaryButton from "../component/button";
import { DatePickerComponent } from "../component/datePickerComponent";
import InputText2 from "../../../../signup/onboarding-setup/component/input2";
import Title from "../component/titleRevamp.js";

const PersonalDetailsScene = ({ setScreen, application }) => {
  const onboarding = new Onboarding();
  const bvnInformation = JSON.parse(localStorage.getItem("bvnInformation"));

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bvnData, setBvnData] = useState();
  const [propagateFormErrors, setPropagateFormErrors] = useState(false);
  const [bvnResponse, setBvnResponse] = useState({
    success: "",
    error: "",
  });
  const [fieldsFocused, setFieldsFocused] = useState([]);
  const [visible, setVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const updatedpersonalDetailsForm = JSON.parse(
    localStorage.getItem("personalDetailsForm")
  );

  const convertDate = (date) => {
    const dateComponents = date?.split("-") || "";
    const year = parseInt(dateComponents[0], 10);
    const month = parseInt(dateComponents[1], 10);
    const day = parseInt(dateComponents[2], 10);

    return `${year}, ${month}, ${day + 1}`;
  };

  const [personalDetailsForm, setPersonalDetailsForm] = useState({
    email: application ? application?.personalDetails?.email : "",
    gender: bvnInformation ? bvnInformation?.gender : "",
    password: application ? application?.personalDetails?.password : "",
    confirmPassword: application
      ? application?.personalDetails?.confirmPassword
      : "",
    phonePrefix: NIGERIA_PHONE_PREFIX,
    dob: new Date(2005, 0, 1),

    // dob: application
    //   ? new Date(2005, 0, 1)
    //   : updatedpersonalDetailsForm
    //   ? new Date(updatedpersonalDetailsForm.dob)
    //   : new Date(2005, 0, 1),
  });

  useEffect(() => {
    const updatedpersonalDetailsForm = JSON.parse(
      localStorage.getItem("personalDetailsForm")
    );

    if (updatedpersonalDetailsForm) {
      setPersonalDetailsForm({
        email: bvnInformation?.email || updatedpersonalDetailsForm.email || "",
        password:
          application?.personalDetails?.password ||
          updatedpersonalDetailsForm.password ||
          "",
        confirmPassword:
          application?.personalDetails?.confirmPassword ||
          updatedpersonalDetailsForm.confirmPassword ||
          "",
        //   phonePrefix: NIGERIA_PHONE_PREFIX,
        //   dob: application
        //     ? new Date(2005, 0, 1)
        //     : updatedpersonalDetailsForm
        //       ? new Date(updatedpersonalDetailsForm.dob)
        //       : new Date(2005, 0, 1),
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem(
      "personalDetailsForm",
      JSON.stringify(personalDetailsForm)
    );
  }, [personalDetailsForm]);

  const updateFormField = (params) => {
    const newForm = {
      ...personalDetailsForm,
      ...params,
    };

    setPersonalDetailsForm(newForm);
  };

  const isSenderPhoneValid = (newForm) => {
    const Form = newForm || personalDetailsForm;
    const countryValidator = phoneValidators[personalDetailsForm.phonePrefix];
    return (
      Form.phoneNumber &&
      (countryValidator ? countryValidator(Form.phoneNumber) : true)
    );
  };

  function formatPhoneNumber(inputNumber) {
    const numStr = inputNumber.replace(/\D/g, "");
    if (numStr.length >= 10) {
      const last10Digits = numStr.slice(-10);

      const result = "234" + last10Digits;

      return result;
    }
  }

  const stripSpacesFromString = (str) => {
    return str.replace(/ /g, "");
  };

  const isBeneficiaryPhoneValid = (newForm) => {
    const Form = newForm || personalDetailsForm;
    const countryValidator = phoneValidators[personalDetailsForm.phonePrefix];

    return (
      Form.phoneNumber &&
      (countryValidator ? countryValidator(Form.phoneNumber) : true)
    );
  };

  const isFormValid = () => {
    return (
      personalDetailsForm.email &&
      personalDetailsForm.password &&
      isEmailValid(personalDetailsForm) &&
      personalDetailsForm.confirmPassword &&
      personalDetailsForm.confirmPassword === personalDetailsForm.password &&
      isPasswordValid(personalDetailsForm?.password) &&
      isConfirmPasswordValid(personalDetailsForm?.confirmPassword)
    );
  };

  const onSubmitButtonClick = async () => {
    // setIsLoading(true);
    // const payload = {
    //   firstName: bvnInformation?.bvnFirstname,
    //   middleName: bvnInformation?.bvnMiddlename,
    //   lastName: bvnInformation?.bvnLastname,
    //   email: personalDetailsForm.email,
    //   gender: bvnInformation?.gender,
    //   mobileNo: bvnInformation?.bvnPhoneNumber,
    //   password: personalDetailsForm.password,
    //   confirmPassword: personalDetailsForm.confirmPassword,
    //   domainCode: "app",
    // };
    // const { status, response } = await userManagement.signupUser(
    //   payload,
    //   true,
    //   false
    // );
    // setIsLoading(false);
    // if (status === ERROR_STATUS) {
    //   firetoast("error", `${response.description}`, "");
    // } else if (status === SUCCESS_STATUS) {
    //   notify("Saved", "success");
    //   localStorage.setItem("applicationId", response.applicationId);
    //   setScreen(7);
    // }
    // setScreen(6);
  };

  const gender = ["Male", "Female"];

  const validateBVN = async () => {
    const date = personalDetailsForm.dob;

    setBvnResponse({
      success: "",
      error: "",
    });
    if (personalDetailsForm.bvn && personalDetailsForm.dob) {
      setLoading(true);
      const payload = {
        bvnNumber: personalDetailsForm.bvn,
        bvnDateOfBirth: specialDateRangeFormatter_StartDateDate(date),
      };
      const { status, response } = await onboarding.validateBVNDetails(payload);
      setBvnData(response);
      localStorage.setItem("BVNData", JSON.stringify(response));

      setLoading(false);
      if (status === ERROR_STATUS) {
        setBvnResponse({ error: response.description });
      } else if (status === SUCCESS_STATUS) {
        setBvnResponse({ success: response.description });
      }
    }
  };

  const handleCopy = (event) => {
    event.preventDefault();
  };

  const handlePaste = (event) => {
    event.preventDefault();
  };

  const NEW_PHONE = "phone is new";
  const NEW_EMAIL = "email is new";
  const USED_PHONE = "phone not new";
  const USED_EMAIL = "email not new";
  const USED_PASSWORD = "password not new";

  function isEmailValidAndNew() {
    return isEmailValid(personalDetailsForm) && isEmailExisting === NEW_EMAIL;
  }

  const userManagement = new UserManagement();

  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [isPasswordLoading, setIsPasswordLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isExisting] = useState(false);
  const [isEmailExisting, setIsEmailExisting] = useState("");

  const validateEmail = async (email) => {
    if (!email) {
      return;
    }

    setIsEmailLoading(true);
    const validateUserObj = await userManagement.validateUserOnPassport(email);

    const { code } = validateUserObj;
    if (code === SUCCESS_CODE) {
      setIsEmailExisting(USED_EMAIL);
      setIsEmailLoading(false);
      setEmailError(false);
    } else if (code === HTTP_NOT_FOUND_CODE) {
      setIsEmailExisting(NEW_EMAIL);
      setIsEmailLoading(false);
      setEmailError(false);
    } else {
      setIsEmailLoading(false);
      setEmailError(true);
    }
  };

  function isPhoneValidAndNew() {
    return (
      isPhoneValid(
        personalDetailsForm.phoneNumber,
        personalDetailsForm.phonePrefix
      ) && isPhoneExisting === NEW_PHONE
    );
  }

  const [isPhoneLoading, setIsPhoneLoading] = useState(false);
  const [isPhoneExisting, setIsPhoneExisting] = useState("");
  const [phoneError, setPhoneError] = useState(false);

  const validatePhone = async (phone) => {
    if (!phone) {
      return;
    }

    setIsPhoneLoading(true);
    const formattedPhone = formatEmailOrPhone(phone);
    const validateUserObj = await userManagement.validateUserOnPassport(
      formattedPhone
    );
    const { code } = validateUserObj;
    if (code === SUCCESS_CODE) {
      setIsPhoneExisting(USED_PHONE);
      setIsPhoneLoading(false);
      setPhoneError(false);
    } else if (code === HTTP_NOT_FOUND_CODE) {
      setIsPhoneExisting(NEW_PHONE);
      setIsPhoneLoading(false);
      setPhoneError(false);
    } else {
      setIsPhoneLoading(false);
      setPhoneError(true);
    }
  };

  return (
    <>
      <BackButton
        onClick={() => {
          setScreen(4);
        }}
      />
      <main className="onboarding-container">
        <Col
          style={{
            marginBottom: "20px",
            margin: "0 auto",
          }}
        >
          <Title pageNum={"4"} />
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
            }}
          >
            Personal Details
          </H1>

          <InputText
            label={"First Name"}
            onChange={(e) => {
              updateFormField({ lastName: e.target.value });
            }}
            labelColor={
              !personalDetailsForm.businessName ||
              isBusinessNameValid(personalDetailsForm)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.businessName ||
              isBusinessNameValid(personalDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your First Name"}
            value={bvnInformation?.bvnFirstname}
            maxLength={200}
            // disabled={true}
          />

          <InputText
            label={"Middle Name"}
            onChange={(e) => {
              updateFormField({ lastName: e.target.value });
            }}
            labelColor={
              !personalDetailsForm.businessName ||
              isBusinessNameValid(personalDetailsForm)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.businessName ||
              isBusinessNameValid(personalDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your Middle Name"}
            value={bvnInformation?.bvnMiddlename}
            maxLength={200}
            // disabled={true}
          />

          <InputText
            label={"Last Name"}
            onChange={(e) => {
              updateFormField({ lastName: e.target.value });
            }}
            labelColor={
              !personalDetailsForm.businessName ||
              isBusinessNameValid(personalDetailsForm)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.businessName ||
              isBusinessNameValid(personalDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your Last Name"}
            value={bvnInformation?.bvnLastname}
            maxLength={200}
            // disabled={true}
          />

          <InputText
            onBlur={() => {
              isNumberValid(personalDetailsForm) &&
                validatePhone(personalDetailsForm.phoneNumber);
            }}
            label={"BVN Phone Number"}
            placeholder={"Enter phone number"}
            onChange={(e) => {
              updateFormField({
                phoneNumber: stripSpacesFromString(e.target.value),
              });
            }}
            labelColor={
              !personalDetailsForm.phoneNumber ||
              isNumberValid(personalDetailsForm.phoneNumber)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.phoneNumber ||
              isNumberValid(personalDetailsForm.phoneNumber)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            value={bvnInformation?.bvnPhoneNumber}
            maxLength={11}
            // disabled={true}
          />

          <div
            style={{
              color: process.env.REACT_APP_RED_COLOUR,
              fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,

              position: "relative",
              top: "-10px",
            }}
          >
            {errMessage}
          </div>

          {isPhoneLoading && (
            <IosRefresh
              style={{
                marginTop: "-52pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              fontSize="60px"
              color="grey"
              rotate={true}
            />
          )}
          {isPhoneValidAndNew() && !isPhoneLoading ? (
            <MdCheckmarkCircle
              style={{
                marginTop: "-45pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              color="green"
              fontSize="40px"
            />
          ) : (
            ""
          )}
          {!personalDetailsForm.phoneNumber ||
          isPhoneValid(
            personalDetailsForm.phoneNumber,
            personalDetailsForm.phonePrefix
          ) ||
          !isExisting ? (
            <React.Fragment />
          ) : (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",
                width: "100%",
              }}
            >
              Phone number is not valid.
            </FormLabel>
          )}
          {isPhoneExisting === USED_PHONE &&
            personalDetailsForm.phoneNumber && (
              <FormLabel
                style={{
                  color: process.env.REACT_APP_RED_COLOUR,
                  fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                  lineHeight: "19px",
                  position: "relative",
                  top: "-15px",
                  width: "100%",
                }}
              >
                Phone number already registered on Quickteller Paypoint. Try
                another number!
              </FormLabel>
            )}
          {phoneError && personalDetailsForm.phoneNumber && (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",
                width: "100%",
              }}
            >
              Failed to validate Phone Number. Try again
            </FormLabel>
          )}

          <InputText
            onBlur={() => {
              isEmailValid(personalDetailsForm) &&
                validateEmail(personalDetailsForm.email);
            }}
            onChange={(e) => {
              updateFormField({ email: e.target.value.trim() });
            }}
            labelColor={
              !personalDetailsForm.email || isEmailValid(personalDetailsForm)
                ? "#3A3B3B"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.email || isEmailValid(personalDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            type={"email"}
            value={personalDetailsForm.email}
            label={"Email address"}
            placeholder={"Enter  email adress"}
            // disabled={true}
          />

          {isEmailLoading && (
            <IosRefresh
              style={{
                marginTop: "-52pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              fontSize="60px"
              color="grey"
              rotate={true}
            />
          )}

          {isEmailValidAndNew() && !isEmailLoading ? (
            <MdCheckmarkCircle
              style={{
                marginTop: "-45pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              color="green"
              fontSize="40px"
            />
          ) : (
            ""
          )}
          {!personalDetailsForm.email ||
          isEmailValid(personalDetailsForm) ||
          !isExisting ? (
            <React.Fragment />
          ) : (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",
                width: "100%",
              }}
            >
              Email is not valid.
            </FormLabel>
          )}
          {isEmailExisting === USED_EMAIL && personalDetailsForm.email && (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",

                width: "100%",
              }}
            >
              Email already registered on Quickteller Paypoint. Try another
              email!
            </FormLabel>
          )}
          {emailError && personalDetailsForm.email && (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",
                width: "100%",
              }}
            >
              Failed to validate Email. Try again
            </FormLabel>
          )}
          <InputText
            label={"Date of birth"}
            placeholder={"Date of birth"}
            onChange={(e) => {
              updateFormField({ dob: stripSpacesFromString(e.target.value) });
            }}
            value={moment(personalDetailsForm.dob).format("DD-MM-YYYY")}
            maxLength={11}
            // disabled={true}
          />
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <SelectInput
              onChange={(e) => {
                updateFormField({ gender: e.target.value });
              }}
              placeholder={"gender"}
              value={bvnInformation ? bvnInformation?.gender : ""}
              option={gender}
              label={"Gender"}
              // disabled={true}
            />
          </div>

          <InputText
            onBlur={() => {
              isNumberValid(personalDetailsForm) &&
                validatePhone(personalDetailsForm.phoneNumber);
            }}
            label={"Preferred Wallet Number"}
            placeholder={"Enter wallet number"}
            onChange={(e) => {
              updateFormField({
                phoneNumber: stripSpacesFromString(e.target.value),
              });
            }}
            labelColor={
              !personalDetailsForm.phoneNumber ||
              isNumberValid(personalDetailsForm.phoneNumber)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.phoneNumber ||
              isNumberValid(personalDetailsForm.phoneNumber)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            value={bvnInformation?.bvnPhoneNumber}
            maxLength={11}
            disabled={false}
          />
          <InputText
            label={"Place of birth"}
            onChange={(e) => {
              updateFormField({ lastName: e.target.value });
            }}
            labelColor={
              !personalDetailsForm.businessName ||
              isBusinessNameValid(personalDetailsForm)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.businessName ||
              isBusinessNameValid(personalDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your place of birth"}
            value={bvnInformation?.bvnLastname}
            maxLength={200}
            disabled={false}
          />

          <InputText
            label={`Mother's Maiden Name`}
            onChange={(e) => {
              const inputValue = e.target.value;
              // const onlyLetters = inputValue.replace(/[^A-Za-z]/g, '');
              updateFormField({ motherMaidenName: inputValue });
            }}
            labelColor={
              !personalDetailsForm.motherMaidenName ||
              isNameValid(personalDetailsForm?.motherMaidenName)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm?.motherMaidenName ||
              isNameValid(personalDetailsForm?.motherMaidenName)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={`Enter Mother's Maiden Name`}
            value={personalDetailsForm?.motherMaidenName}
            maxLength={200}
          />

          <PrimaryButton
            // disabled={!isFormValid() || isLoading}
            onClick={onSubmitButtonClick}
            // onClick={() => setScreen(7)}
          >
            {" "}
            {isLoading ? "Processing..." : "Next"}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

export default PersonalDetailsScene;
