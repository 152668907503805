import React, { useEffect, useRef, useState } from "react";
import { Col, Form } from "react-bootstrap";
import H1 from "../../../../components/h1";
import PrimaryButton from "../component/button";
import InputText from "../component/input";
import HeaderSignup from "../../../../components/header-signup";
import Stepper from "../component/stepper";
import { isNINNumberValid } from "../component/extras";

const KycDocumentScene = ({ setScreen, application }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [kycDetailsForm, setKycDetailsForm] = useState({
    nin: '',
  });

  useEffect(() => {
    const updatedKycDetailsForm = JSON.parse(
      localStorage.getItem("kycDetailsForm")
    );

    if (updatedKycDetailsForm) {
      setKycDetailsForm({
        nin:
          application?.kycDetailsForm?.nin ||
          updatedKycDetailsForm.nin ||
          "",
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem(
      "kycDetailsForm",
      JSON.stringify(kycDetailsForm)
    );
  }, [kycDetailsForm]);

  const updateFormField = (params) => {
    const newForm = {
      ...kycDetailsForm,
      ...params,
    };

    setKycDetailsForm(newForm);
  };

  const isFormValid = () => {
    return (
      kycDetailsForm.nin &&
      isNINNumberValid(kycDetailsForm)
    );
  };

  return (
    <>
      <HeaderSignup />
      <main className="onboarding-container">
        <Col
          style={{
            margin: "20px auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: "10px",
              marginBottom: "30px",
            }}
          >
            <Stepper borderColor="#00425F" />
            <Stepper borderColor="#00425F" />
            <Stepper borderColor="#00425F" />
            <Stepper borderColor="#00425F" />
            <Stepper />
          </div>
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: ".2rem",
            }}
          >
            KYC Information
          </H1>

          <InputText
            label={'Enter NIN'}
            labelColor={
              !kycDetailsForm.nin ||
                isNINNumberValid(kycDetailsForm)
                ? 'black'
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${!kycDetailsForm.nin ||
              isNINNumberValid(kycDetailsForm)
              ? '#DFE2E6'
              : process.env.REACT_APP_RED_COLOUR
              }`}
            placeholder={'Enter your National Identification Number'}
            onChange={(e) => {
              updateFormField({ nin: e.target.value });
            }}
            maxLength={11}
            value={kycDetailsForm.nin}
          />

          <PrimaryButton
            disabled={!isFormValid() || isLoading}
            // onClick={handleSubmit}
            onClick={() => setScreen(4)}
          >
            {isLoading ? "Processing..." : "Next"}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

export default KycDocumentScene;
