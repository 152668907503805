import React, { useCallback, useEffect, useState, useRef } from 'react';
import PrimaryButton from '../component/button';
import IosRefresh from "react-ionicons/lib/IosRefresh";
import Modal from "react-bootstrap/Modal";

import { Col } from 'react-bootstrap';
import H1 from '../../../../components/h1';
import InputText from '../component/input';

import { firetoast, notify } from '../../../../components/extras';
import HeaderSignup from '../../../../components/header-signup';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../constants/api';
import {
  isConfirmPasswordValid,
  isPasswordValid,
  isPhoneNumberValid,
} from '../component/extras';
import Stepper from '../component/stepper';
import { greenCheckIcon, InfoIcon2 } from '../../../../icons';
import UserManagement from '../../../../services/api/resources/user-management';
import { formatPhoneNumber } from '../../../../utils/formatters';

const SecureAccountScene = ({ setScreen, application }) => {
  const userManagement = new UserManagement();
  const bvnInformation = JSON.parse(localStorage.getItem('bvnInformation'));


  const [isLoading, setIsLoading] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [isLoadingOtp, setIsLoadingOtp] = useState(false);
  const [tokenId, setTokenId] = useState("");
  const [loading, setLoading] = useState(false);
  const [preferredPhoneNumber, setPreferredPhoneNumber] = useState("12345678901");
  const [isYesSelected, setIsYesSelected] = useState(true);
  const [isOtpIncorrect, setIsOtpIncorrect] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const otpInputsRef = useRef([]);

  const [secureAccountDetailsForm, setSecureAccountDetailsForm] = useState({
    preferredNumber: '',
    password: '',
    confirmPassword: '',
  });

  useEffect(() => {
    const updatedSecureAccountDetailsForm = JSON.parse(
      localStorage.getItem("secureAccountDetailsForm")
    );

    if (updatedSecureAccountDetailsForm) {
      setSecureAccountDetailsForm({
        preferredNumber:
          application?.secureAccountDetailsForm?.preferredNumber ||
          updatedSecureAccountDetailsForm.preferredNumber ||
          "",
        password:
          application?.secureAccountDetailsForm?.password ||
          updatedSecureAccountDetailsForm.password ||
          "",
        confirmPassword:
          application?.secureAccountDetailsForm?.confirmPassword ||
          updatedSecureAccountDetailsForm.confirmPassword ||
          "",
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem(
      "secureAccountDetailsForm",
      JSON.stringify(secureAccountDetailsForm)
    );
  }, [secureAccountDetailsForm]);

  const updateFormField = (params) => {
    const newForm = {
      ...secureAccountDetailsForm,
      ...params,
    };

    setSecureAccountDetailsForm(newForm);
  };

  const handleRadioChange = (e) => {
    const value = e.target.value;
    if (value === "yes") {
      // setPreferredPhoneNumber(bvnInformation?.proposedNumber);
      setPreferredPhoneNumber("12345678901");
      setIsYesSelected(true);
    } else {
      setPreferredPhoneNumber("");
      setIsYesSelected(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    // const payload = {
    //   businessDetails: {
    //     state: businessDetailsForm.state,
    //     address: businessDetailsForm.businessAddress,
    //     businessName: businessDetailsForm.businessName,
    //     localGovernmentArea: businessDetailsForm.localGovernmentArea,
    //   },
    // };

    // const { status, response } = await onboarding.putApplication(
    //   payload,
    //   applicationId
    // );

    // setIsLoading(false);
    // if (status === ERROR_STATUS) {
    //   firetoast("error", `${response.description}`, "");
    //   setIsLoading(false);
    // } else if (status === SUCCESS_STATUS) {
    //   notify("Saved", "success");
    //   setScreen(5);
    // }
  };

  const callResendOtp = async () => {
    setIsLoading(true);
    setIsLoadingOtp(true);

    const responseObj = await userManagement.resendOtp(
      formatPhoneNumber("08161564659")
    );
    const { status, response } = responseObj;
    const newResponse = response.toString().replace(/[, ]+/g, "").trim();
    setIsLoading(false);
    if (status === ERROR_STATUS) {
      firetoast("error", `${newResponse.description}`, "");
      setIsOtpIncorrect(true)
      setIsLoadingOtp(false)
    } else if (status === SUCCESS_STATUS) {
      notify("OTP successfully sent!", "success");
      setIsOtpIncorrect(false)
      setIsLoadingOtp(false)
    }
  };

  const navigateToFaceVerification = () => {
    setScreen(2);
    setShowModal(false);
    setShowOtpModal(false);
  };

  const textStyle = {
    color: "#1F2937",
    fontWeight: "500",
    fontSize: "0.8rem",
    letterSpacing: "0.3px",
  };

  const spanStyle = {
    display: "inline-flex",
    alignItems: "center",
  };

  const pStyle = {
    marginLeft: "10px",
    marginTop: "12px",
  };

  const iconStyle = {
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const instructions = [
    "Ensure you are in a well-lit area",
    "Make sure you are in front of a plain background",
    "Make sure to remove hats, thick glasses, or anything else that might obscure your face",
    "Make sure you keep your expressions neutral",
  ];

  function debounce(fn, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn.apply(this, args), delay);
    };
  }

  const showFaceIdModalWithDelay = () => {
    setTimeout(() => {
      setShowModal(true);
    }, 5000);
  };

  const handleOtpSubmit = async () => {
    setLoading(true);
    const otpString = otp.join("");
    const requestBody = {
      otp: otpString,
      isSomething: false,
      isAnotherThing: true
    };
    const responseObj = await userManagement.signupUser(requestBody);
    const { status, response } = responseObj;
    setLoading(false);

    if (status === ERROR_STATUS) {
      notify(`${response.description}`, "error");
      setIsOtpIncorrect(true);
      setTimeout(() => {
        showFaceIdModalWithDelay();
      }, 1000);
    } else if (status === SUCCESS_STATUS) {
      setIsOtpIncorrect(false);
      setTimeout(() => {
        showModal(true);
      }, 1000);
    }
  };

  const debouncedHandleOtpSubmit = useCallback(
    debounce(handleOtpSubmit, 1000),
    [otp]
  );

  const handleOtpChange = (e, index) => {
    const { value } = e.target;

    if (/^[0-9]$/.test(value)) {
      let newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 5) {
        otpInputsRef.current[index + 1].focus();
      } else {
        otpInputsRef.current[index].blur();
        debouncedHandleOtpSubmit();
      }
    } else {
      e.target.value = "";
    }
  };

  const handleShowModal = () => {
    if (isYesSelected) {
      setShowModal(true);
    } else {
      setShowOtpModal(true);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setShowOtpModal(false);
  };

  const isFormValid = () => {
    if (isYesSelected) {
      return (
        secureAccountDetailsForm.password &&
        secureAccountDetailsForm.confirmPassword &&
        (secureAccountDetailsForm.password === secureAccountDetailsForm.confirmPassword) &&
        isPasswordValid(secureAccountDetailsForm.password) &&
        isConfirmPasswordValid(secureAccountDetailsForm.confirmPassword)
      );
    } else {
      return (
        secureAccountDetailsForm.preferredNumber &&
        secureAccountDetailsForm.password &&
        secureAccountDetailsForm.confirmPassword &&
        (secureAccountDetailsForm.password === secureAccountDetailsForm.confirmPassword) &&
        isPhoneNumberValid(secureAccountDetailsForm.preferredNumber) &&
        isPasswordValid(secureAccountDetailsForm.password) &&
        isConfirmPasswordValid(secureAccountDetailsForm.confirmPassword)
      );
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          gap: '40px',
        }}
      >
        <HeaderSignup />
      </div>
      <main className='onboarding-container'>
        <Col
          style={{
            marginBottom: '20px',
            width: '80%',
            margin: '0 auto',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              gap: '10px',
              marginBottom: '30px',
            }}
          >
            <Stepper borderColor='#00425F' />
            <Stepper borderColor='#00425F' />
            <Stepper />
            <Stepper />
            <Stepper />
          </div>
          <H1
            style={{
              textAlign: 'left',
              fontWeight: 600,
              marginBottom: '10px',
            }}
          >
            Secure your Account
          </H1>
          <p
            style={{
              color: "grey",
              fontWeight: "500",
              fontSize: "12px",
            }}
          >
            Enter the details below to secure your account
          </p>
          <p style={{
            color: "black",
            fontWeight: "700",
            fontSize: "10px",
          }}>Do you want to use your BVN phone number as wallet number?</p>

          <div className="mb-2">
            <label className="mr-2">
              <input
                type="radio"
                name="usePreferredPhone"
                value="yes"
                checked={isYesSelected}
                onChange={handleRadioChange}
              />
              Yes
            </label>
            <label className="ml-4">
              <input
                type="radio"
                name="usePreferredPhone"
                value="no"
                checked={!isYesSelected}
                onChange={handleRadioChange}
              />
              No
            </label>
          </div>

          <InputText
            label={'Preferred Phone Number'}
            onChange={(e) => {
              updateFormField({ preferredNumber: e.target.value });
            }}
            labelColor={
              !secureAccountDetailsForm.preferredNumber ||
                isPhoneNumberValid(secureAccountDetailsForm.preferredNumber)
                ? 'black'
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${!secureAccountDetailsForm.preferredNumber ||
              isPhoneNumberValid(secureAccountDetailsForm.preferredNumber)
              ? '#DFE2E6'
              : process.env.REACT_APP_RED_COLOUR
              }`}
            placeholder={'Enter your Preferred Phone Number'}
            value={isYesSelected ? preferredPhoneNumber : secureAccountDetailsForm.preferredNumber}
            maxLength={11}
            disabled={isYesSelected ? true : false}
          />

          <InputText
            label={'Enter Password'}
            labelColor={
              !secureAccountDetailsForm.password ||
                isPasswordValid(secureAccountDetailsForm.password)
                ? 'black'
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${!secureAccountDetailsForm.password ||
              isPasswordValid(secureAccountDetailsForm.password)
              ? '#DFE2E6'
              : process.env.REACT_APP_RED_COLOUR
              }`}
            placeholder={'Enter your password'}
            onChange={(e) => {
              updateFormField({ password: e.target.value });
            }}
            maxLength={200}
            value={secureAccountDetailsForm.password}
          />
          <InputText
            label={'Confirm Password'}
            labelColor={
              !secureAccountDetailsForm.confirmPassword ||
                isConfirmPasswordValid(secureAccountDetailsForm.confirmPassword)
                ? 'black'
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${!secureAccountDetailsForm.confirmPassword ||
              isConfirmPasswordValid(secureAccountDetailsForm.confirmPassword)
              ? '#DFE2E6'
              : process.env.REACT_APP_RED_COLOUR
              }`}
            placeholder={'Enter confirm password'}
            onChange={(e) => {
              updateFormField({ confirmPassword: e.target.value });
            }}
            maxLength={200}
            value={secureAccountDetailsForm.confirmPassword}
          />
          {secureAccountDetailsForm.password !== secureAccountDetailsForm.confirmPassword ?
            <span style={{ fontSize: "12px", color: "#DC4437" }}>Passwords do not match.</span> : null
          }

          <PrimaryButton
            disabled={!isFormValid() || isLoading}
            onClick={handleShowModal}
          >
            {isLoading ? 'Processing...' : 'Next'}
          </PrimaryButton>
        </Col>
      </main>

      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        style={{
          paddingTop: "6rem",
        }}
      >
        <Modal.Body style={{ padding: "2rem" }}>
          <div style={{ alignContent: "center", padding: "1rem" }}>
            <h1
              style={{
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              Face ID Verification
            </h1>
            <p
              style={{
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "20px",
                color: "#1F2937",
              }}
            >
              Ensure your camera is steady and your face fits into the shape.
            </p>
            <div style={textStyle}>
              {instructions.map((instruction, index) => (
                <span key={index} style={spanStyle}>
                  <div style={iconStyle}>{greenCheckIcon()}</div>
                  <p style={pStyle}>{instruction}</p>
                </span>
              ))}
            </div>

            <button
              onClick={navigateToFaceVerification}
              className="signupButton2"
              style={{
                marginTop: "2rem",
                textAlign: "center",
                color: "white",
                backgroundColor: "#00425f",
                width: "100%",
              }}
            >
              {isLoading ? "Processing..." : "Next"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {!isYesSelected &&
        <Modal
          closeButton
          show={showOtpModal}
          aria-labelledby="contained-modal-title-vcenter"
          style={{
            backdropFilter: "blur(8px)",
            backgroundColor: "rgba(16, 24, 40, 0.3)",
            paddingTop: "6rem",
          }}
          dialogClassName="custom-modal"
          onHide={handleClose}
        >
          <Modal.Body style={{ padding: "3rem" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <form className="otp-container" style={{ maxWidth: "500px", width: "100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}> <span style={{ fontSize: "24px", fontWeight: 700, marginTop: "12px" }}>Enter OTP</span>
                  <Modal.Header closeButton></Modal.Header>
                </div>
                <p style={{ fontSize: "12px", color: "#5F738C" }}>
                  An OTP was sent to your Phone number 08078****54. Kindly enter it below.
                </p>

                <div style={{
                  justifyContent: "center",
                }}>
                  <label
                    htmlFor="otp-input"
                    style={{ fontSize: "14px", color: "black", fontWeight: 700, display: "block" }}
                  >
                    OTP
                  </label>

                  <div
                    style={{
                      display: "flex",
                      gap: "14px",
                      marginBottom: "2px",
                      width: "100%",
                    }}
                  >
                    {[...Array(6)].map((_, index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength="1"
                        style={{
                          width: "50px",
                          height: "50px",
                          textAlign: "center",
                          fontSize: "20px",
                          borderRadius: "8px",
                          border: `1px solid ${isOtpIncorrect ? "#DC4437" : "#ccc"}`,
                          backgroundColor: " #e0e0e0",
                        }}
                        onChange={(e) => handleOtpChange(e, index)}
                        onFocus={(e) => e.target.select()}
                        ref={(el) => (otpInputsRef.current[index] = el)}
                      />
                    ))}
                    {loading ? (
                      <IosRefresh fontSize="24px" color="grey" rotate={true} />
                    ) : (
                      <span></span>)}
                  </div>

                  <div style={{ textAlign: "left", marginTop: "1px" }}>
                    {isLoadingOtp ? (
                      <IosRefresh fontSize="24px" color="grey" rotate={true} />
                    ) : (
                      <span
                        style={{ cursor: "pointer", color: "#0047ab", fontSize: "12px" }}
                        className="otp-resend"
                        onClick={callResendOtp}
                      >
                        Resend OTP
                      </span>
                    )}
                  </div>

                  <div style={{ textAlign: "left", marginTop: "10px" }}>
                    {isOtpIncorrect && (
                      <>
                        <InfoIcon2 fontSize="24px" color="#DC4437" />
                        <span
                          style={{
                            marginLeft: "10px",
                            color: "#DC4437",
                          }}
                          className="otp-error"
                        >
                          OTP is incorrect
                        </span>
                      </>
                    )}

                  </div>

                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      }
    </>
  );
};

export default SecureAccountScene;
